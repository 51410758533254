<template>
  <b-container>
    <b-form>
      <b-row>
        <b-col lg="6" sm="8" xs="12">
          <h2>Add Record</h2>
          <b-form-group id="discogsId-group" label="Discogs Release ID:" label-for="discogsId">
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>r</b-input-group-prepend>
              <b-form-input id="discogsId" v-model="form.discogsId" type="number" required ref="discogsId" />
              <b-input-group-append>
                <b-button type="submit" @click.prevent="getDiscogsRecord" variant="success" ref="find"
                  :disabled="form.discogsId > 1 && form.discogsId > 999999999">
                  Find
                </b-button>
              </b-input-group-append>

              <b-link id="popover-target-1" :style="{ alignSelf: 'center', marginLeft: '40px' }">
                What is this?
              </b-link>
              <b-popover target="popover-target-1" triggers="hover" placement="right">
                <template #title>How to Find a Discogs ID</template>
                <img src="/images/discogs_release.png" alt="Locating Discogs ID" :style="{ float: 'right' }" />
                <ol>
                  <li>
                    Go to
                    <b-link href="https://www.discogs.com" target="_blank">
                      Discogs
                    </b-link>
                    .
                  </li>
                  <li>Search for the release you wish to add.</li>
                  <li>
                    Copy the Release Code located at the top right of the
                    screen.
                  </li>
                  <li>
                    Paste it into the 'Discogs Release ID' field and click
                    'Find'.
                  </li>
                  <li>
                    <em>
                      Note: Ensure it's the Release ID (starting with
                      'r'), not the Master ID (starting with 'm').
                    </em>
                  </li>
                </ol>
              </b-popover>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-form-group id="artist-group" label="Artist*:" label-for="artist">
            <b-form-input id="artist" v-model.trim="form.artist"
              @blur="setValidationTouched('artist', $event.target.value)" :state="validateState('artist')" required />
            <b-form-invalid-feedback v-if="!$v.form.artist.required && $v.form.artist.$dirty">
              Artist is required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="sideatitle-group" label="Side A*:" label-for="sideatitle">
            <b-form-input id="sideatitle" v-model.trim="form.sideA"
              @blur="setValidationTouched('sideA', $event.target.value)" :state="validateState('sideA')" required />
            <b-form-invalid-feedback v-if="!$v.form.sideA.required && $v.form.sideA.$dirty">
              Side A is required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="sidebtitle-group" label="Side B*:" label-for="sidebtitle">
            <b-form-input id="sidebtitle" v-model.trim="form.sideB"
              @blur="setValidationTouched('sideB', $event.target.value)" :state="validateState('sideB')" required />
            <b-form-invalid-feedback v-if="!$v.form.sideB.required && $v.form.sideB.$dirty">
              Side B is required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group id="year-group" label="Year:" label-for="year">
                <b-form-input id="year" v-model.trim="form.year" type="number" :min="0" />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group id="speed-group" label="Speed:*" label-for="speed">
                <b-button id="speed" :variant="`${form.speed === SPEED_45 ? '' : 'outline-'}success`"
                  @click="form.speed = SPEED_45" class="mr-1">{{ SPEED_45 }}</b-button>
                <b-button :variant="`${form.speed === SPEED_78 ? '' : 'outline-'}success`"
                  @click="form.speed = SPEED_78">{{ SPEED_78 }}</b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <h4>Preview</h4>
          <div>
            <Card45 :artist="form.artist || 'Artist'" :sideA="form.sideA || 'Side A'" :sideB="form.sideB || 'Side B'"
              v-if="form.speed === SPEED_45" class="mb-1" />
            <Card78 :artist="form.artist || 'Artist'" :title="form.sideA || 'Side A'" v-if="form.speed === SPEED_78" />
            <Card78 :artist="form.artist || 'Artist'" :title="form.sideB || 'Side B'" v-if="form.speed == SPEED_78"
              class="mt-1 mb-1" />
          </div>
          <!-- <b-form-group
            id="acquired-group"
            label="Acquired Date:"
            label-for="acquired"
            description="Format YYYY-MM-DD"
          >
            <b-input-group>
              <b-form-datepicker id="acquired" v-model="form.acquired" />
              <b-input-group-append>
                <b-button variant="outline-info" @click="setToday">
                  Today
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group> -->

          <b-row>
            <b-col class="pt-3">
              <b-alert :show="printOptions.length < 2" variant="warning">Goto <b-link :to="{ name: 'print' }">Print
                  Shop</b-link>
                add a
                create a Print Set to add new records to</b-alert>
              <b-form-group id="addToPrintSet" label="Add to Printset:" v-if="printOptions.length >= 2">
                <b-form-select v-model="addToPrintSet" :options="printOptions" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="addToCollection" class="pt-3" v-if="hasDiscogs">
                <b-form-checkbox v-model="addToCollection">
                  Add to Discogs Collection
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button @click="resetForm" variant="outline-primary" class="mr-3">Reset</b-button>
          <b-button type="submit" variant="primary" @click="onAdd" :disabled="this.$v.$invalid" ref="add">
            Add Record
          </b-button>
        </b-col>



      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { SPEED_78, SPEED_45 } from "@/consts";
import Card45 from "@/components/45/Card";
import Card78 from "@/components/78/Card";
import {
  findDiscogsRecord,
  convertDiscogsToForm,
  saveToDiscogsCollection,
} from "../discogs";
import { addRecord, addRecordsToPrintSet } from "@/requests";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddRecordComponent",
  data() {
    return {
      SPEED_78,
      SPEED_45,
      form: {
        discogsId: null,
        acquired: new Date().toISOString().split("T")[0],
        csong: false,
        year: null,
        speed: SPEED_45,
        artist: "",
        sideA: "",
        sideB: "",
      },
      response: {},
      addToCollection: true,
      addToPrintSet: null,
      speed: [SPEED_45, SPEED_78],
    };
  },
  validations: {
    form: {
      artist: {
        required,
      },
      speed: {
        required,
      },
      sideA: {
        required,
      },
      sideB: {
        required,
      },
    },
  },
  methods: {
    setValidationTouched(field, value) {
      this.form[field] = value;
      this.$v.form[field].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    // setToday() {
    //   this.form.acquired = new Date().toISOString().split("T")[0];
    // },
    getDiscogsRecord() {
      findDiscogsRecord(this.form.discogsId)
        .then((response) => {
          this.response = response;
          convertDiscogsToForm(response, this.form);

          // this can be response.keys.map
          this.$v.form.speed.$touch();
          this.$v.form.artist.$touch();
          this.$v.form.sideA.$touch();
          this.$v.form.sideB.$touch();

          this.$refs.add.focus();
        })
        .catch(() => {
          this.$bvToast.toast(`Invalid song ID`, {
            title: "Couldn't find song",
            variant: "info",
            toaster: "b-toaster-top-right",
            solid: true,
          });
        });
    },
    resetForm() {
      this.form.discogsId = null;
      this.form.year = null;
      this.form.artist = null;
      this.form.sideA = null;
      this.form.sideB = null;
      this.$refs.discogsId.focus();
      this.$v.$reset();
    },
    onAdd(event) {
      event.preventDefault();
      addRecord(this.discShape).then((results) => {
        // Ad to Print Set
        if (this.addToPrintSet) {
          addRecordsToPrintSet(this.addToPrintSet, [results[0].data.data.insertOneRecord._id])
        }

        // Add to Discogs Collection
        if (
          this.addToCollection &&
          this.hasDiscogs &&
          this.form.discogsId &&
          parseInt(this.form.discogsId) > 0
        ) {
          this.addToCollectionPost(this.form.discogsId).then(() => {
            this.onAddResult();
          });
        } else {
          this.onAddResult();
        }
      });
    },
    onAddResult() {
      let message = `${this.form.artist}, ${this.form.sideA}, ${this.form.sideB}`;
      this.$bvToast.toast(message, {
        title: "Record Added",
        variant: "info",
        toaster: "b-toaster-top-right",
        solid: true,
      });
      this.resetForm();
    },
    addToCollectionPost(discogsId) {
      return saveToDiscogsCollection(
        this.user.meta.discogsUsername,
        discogsId,
        this.user.meta.discogsToken
      ).catch((e) => {
        this.$bvToast.toast(`Error: ${e}`, {
          title: "Discogs add failed to a Added",
          variant: "error",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      });
    },
  },
  mounted() {
    if (this.printOptions && this.printOptions.length > 1) {
      this.addToPrintSet = this.printOptions[1].value
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    hasDiscogs() {
      return (
        this.user.meta.discogsToken && this.user.meta.discogsToken.length >= 35
      );
    },
    printOptions() {
      const options = this.$store.getters.getPrints.filter((print) => print.status === "OPEN").map((print) => {
        return {
          text: print.title,
          value: print._id,
        };
      });
      options.unshift({ text: 'None', value: null });
      return options;
    },
    discShape: function () {
      return {
        acquired: new Date(this.form.acquired),
        artist: this.form.artist,
        discogsId: this.form.discogsId,
        meta: {
          beenResident: false,
          coon: this.form.csong,
          position: false,
        },
        side: {
          a: {
            title: this.form.sideA,
          },
          b: {
            title: this.form.sideB,
          },
        },
        speed: this.form.speed,
        year: this.form.year,
      };
    },
  },
  components: {
    Card45,
    Card78,
  },
};
</script>
